import React from 'react';
import { Link } from 'gatsby';

import Button from './Button';
import Block from './Block';
import P from './P';
import Grid from './Grid';
import Cell from './Cell';

const PageCTA = () => (
  <Grid halign="center" py="baseUp8">
    <Cell
      size={{ mobile: 1, smallTablet: 1 / 2 }}
      px={{ mobile: 'baseUnit', smallTablet: '0' }}
    >
      <P>
        Bokaplanen är en tjänst som gör det lika enkelt att boka träningstider
        som att lägga in ett möte i kalendern på din smartphone, så att du kan
        ägna mer tid åt din förening.
      </P>

      <P>
        Glöm de långa timmar ni fått sitta på kansliet och pussla med
        tidsbokningar; med Bokaplanens enkla gränssnitt gör ni bokningarna på
        nolltid.
      </P>

      <Block display="flex" justifyContent="center" pt="baseUp4">
        <Link to="/signup">
          <Button backgroundColor="pink" color="white">
            Boka en demo
          </Button>
        </Link>
      </Block>
    </Cell>
  </Grid>
);

export default PageCTA;
