import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const layerHeight = '4px';

const PlusMinusBox = styled.span`
  width: ${({ theme }) => theme.spacing.baseUnit};
  height: ${({ theme }) => theme.spacing.baseUnit};
  display: inline-block;
  position: relative;
`;

const PlusMinusInner = styled.span`
  top: calc(50% - (${layerHeight} / 2));

  &,
  &::before {
    display: block;
    position: absolute;
    content: ' ';
    background-color: ${({ theme }) => theme.colors.baseFont};
    border-radius: 2px;
    transition-duration: 0.1s;
    transition-property: transform;
    transition-timing-function: ease;
    width: 100%;
    height: ${layerHeight};
  }

  &::before {
    left: 0;
    transform: ${({ isActive }) =>
      isActive ? 'rotate(0deg)' : 'rotate(90deg)'};
  }
`;

const PlusMinus = ({ isActive }) => (
  <PlusMinusBox>
    <PlusMinusInner isActive={isActive} />
  </PlusMinusBox>
);

PlusMinus.defaultProps = {
  isActive: false,
};

PlusMinus.propTypes = {
  isActive: PropTypes.bool,
};

export default PlusMinus;
