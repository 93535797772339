import React from 'react';
// import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Img from 'gatsby-image';

import Cell from './Cell';
import Grid from './Grid';
import H2 from './H2';
import P from './P';

const HeroVideoWrapper = styled.div`
  position: relative;
  overflow: hidden;
  max-height: 900px;
  min-height: 450px;
`;

const AbsoluteFill = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Darkener = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`;

const AspectRatio16x9 = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 145%;

  ${breakpoint('smallTablet')`
    padding-bottom: 80%;
  `};
  ${breakpoint('tablet')`
    padding-bottom: 60%;
  `};
  ${breakpoint('desktop')`
    padding-bottom: 45%;
  `};
  ${breakpoint('large')`
    padding-bottom: 35%;
  `};
`;

const HeroImage = styled(Img)`
  z-index: -1;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const PageHero = () => {
  const { heroImage } = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "hero-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <HeroVideoWrapper>
      <AspectRatio16x9>
        <AbsoluteFill>
          <Darkener />

          <HeroImage
            fluid={heroImage.childImageSharp.fluid}
            alt="hero"
            style={{ position: 'absolute' }}
          />
        </AbsoluteFill>
      </AspectRatio16x9>

      <AbsoluteFill>
        <Grid
          height="100%"
          valign="center"
          pb={{ mobile: 'baseUnit', smallTablet: 'baseUp4' }}
          pt={{
            mobile: 'baseUp4',
            smallTablet: 0,
          }}
        >
          <Cell
            size={{ mobile: 1, tablet: 1 / 2 }}
            p={{
              mobile: 'baseUp4',
              smallTablet: 'baseUp8',
              tablet: 'baseUnit',
            }}
          >
            <H2 color="white" mb="baseUnit">
              Ett bättre sätt att boka träningstider
            </H2>
            <P color="white">
              Använder er förening fortfarande Excel eller papper och penna för
              att hålla reda på träningstider? Då vill vi erbjuda ett bättre
              alternativ.
            </P>
          </Cell>
        </Grid>
      </AbsoluteFill>
    </HeroVideoWrapper>
  );
};

PageHero.propTypes = {};

export default PageHero;
