import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import P from './P';
import Anchor from './Anchor';
import H1 from './H1';
import H2 from './H2';
import H3 from './H3';

const headerLevelLookup = [null, H1, H2, H3];

const H = props => {
  const { level } = props;
  const Component = headerLevelLookup[level];

  return <Component {...props} />;
};

H.propTypes = {
  level: PropTypes.number.isRequired,
};

const Markdown = ({ content, color }) => (
  <ReactMarkdown
    source={content}
    renderers={{
      heading: props => <H color={color} {...props} />,
      paragraph: props => <P color={color} {...props} />,
      link: props => <Anchor {...props} />,
    }}
  />
);

Markdown.defaultProps = {
  color: null,
};

Markdown.propTypes = {
  content: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default Markdown;
