import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const Blockquote = styled.blockquote`
  position: relative;
  padding-left: ${({ theme }) => theme.spacing.baseUp1};
  font-size: ${({ theme }) => theme.spacing.baseUnit};
  font-style: italic;

  span {
    display: block;
    margin-bottom: ${({ theme }) => theme.spacing.baseUnit};
  }

  ${breakpoint('smallTablet')`
    padding-left: 0;
    font-size: ${({ theme }) => theme.spacing.baseUp1};
  `};

  &::before {
    display: block;
    position: absolute;
    font-family: Georgia, serif;
    content: '\\201C';
    font-size: 5rem;
    top: -1rem;
    left: -${({ theme }) => theme.spacing.baseUp2};
    color: ${({ theme }) => theme.colors.grayDarker};

    ${breakpoint('smallTablet')`
      left: -${({ theme }) => theme.spacing.baseUp10};
      font-size: 6rem;
    `};
  }

  cite {
    display: block;
    color: ${({ theme }) => theme.colors.grayDarker};
    font-size: ${({ theme }) => theme.spacing.baseDown1};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-align: right;
    font-style: normal;

    &::before {
      content: '\\2014 \\2009';
    }
  }
`;

export default Blockquote;
