import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Blockquote from './Blockquote';
import Grid from './Grid';
import Cell from './Cell';

const items = {
  bp: {
    paragraphs: [
      'Vi använder Bokaplanen för att hålla koll på vilka lag som tränar på vilken plan och tid. Med 250 lag i föreningen så fördelar vi ofta in planerna i mindre ytor, allt ifrån planhalvor ända ner till 1/6-delar.',
      'Systemet hjälper oss då att visa vart lagen är placerade på planen samt kalkylerar ut eventuella planhyror så att rätt lag står för rätt kostnad.',
      'Utan Bokaplanen skulle det vara en administrativ mardröm att hålla koll på alla lag och deras plankostnader / bokningar.',
    ],
    source:
      'Carl Halvardsson, Ungdomskonsulent & webbansvarig, IF Brommapojkarna',
  },
  dif: {
    paragraphs: [
      'Vi i Djurgården använder Bokaplanen till att schemalägga hela föreningens träningstider.',
      'Som storklubb i innerstaden är våra tränings-förutsättningar väldigt begränsade, med hjälp av Bokaplanen kan vi effektivisera användningen av planerna genom att lätt boka av och på nya lag så att inga tider står oanvända.',
      'Bokaplanen sköter även kommunikationen med våra ledare som automatiskt får ett mail så fort deras lag gjort en bokning eller avbokning vilket sparar massor med tid dagligen.',
    ],
    source: 'Linn Andersson, Djurgården Fotboll',
  },
};

const PageQuote = ({ team }) => {
  const images = useStaticQuery(graphql`
    query {
      bp: file(relativePath: { eq: "logo-bp.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dif: file(relativePath: { eq: "logo-dif.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const content = items[team];

  return (
    <Grid py="baseUp8" valign="center">
      <Cell
        size={{ mobile: 1, smallTablet: 1 / 3 }}
        px={{ mobile: 'baseUp12', smallTablet: 'baseUp4', tablet: 'baseUp12' }}
        mb={{ mobile: 'baseUp8', smallTablet: '0' }}
      >
        <Img fluid={images[team].childImageSharp.fluid} />
      </Cell>
      <Cell
        size={{ mobile: 1, smallTablet: 2 / 3 }}
        px={{ mobile: 'baseUp4', smallTablet: 'baseUp8' }}
      >
        <Blockquote>
          {content.paragraphs.map((paragraph, i) => (
            <span key={`quote-team-${team}-p-${i}`}>{paragraph}</span>
          ))}

          <cite>{content.source}</cite>
        </Blockquote>
      </Cell>
    </Grid>
  );
};

PageQuote.propTypes = {
  team: PropTypes.string.isRequired,
};

export default PageQuote;
