import React from 'react';
// import PropTypes from 'prop-types';

import Grid from './Grid';
import Cell from './Cell';
import H3 from './H3';
import Accordion from './Accordion';

const items = [
  {
    id: 0,
    title: 'Vad kostar det?',
    paragraphs: [
      `Vår tanke är att oavsett hur stor din klubb är ska det löna sig att 
      använda Bokaplanen. Hör av dig till [info@bokaplanen.se](mailto:info@bokaplanen.se), så berättar vi mer.`,
    ],
  },
  {
    id: 1,
    title: 'Kan jag se hur det fungerar någonstans?',
    paragraphs: [
      `Visst kan du det! Vi har en demo tillgänglig - [demo.bokaplanen.se](https://demo.bokaplanen.se).`,
    ],
  },
  {
    id: 2,
    title: 'Behöver jag byta medlemssystem?',
    paragraphs: [
      `Nej - Bokaplanen kan importera medlemmar från ert befintliga
      medlemssystem. Alla ändringar som görs i ert medlemssystem kan sen föras
      över till Bokaplanen med ett klick. Vi har just nu integrationer med
      MyClub, Laget.se och Attendance. Om ni använder ett annat system kan vi
      bygga ut vår import för att stödja det.`,
    ],
  },
  {
    id: 3,
    title: 'Hur är det med GDPR?',
    paragraphs: [
      `Vi tar mycket allvarligt på hantering av personuppgifter och vidtar alla
      åtgärder som krävs för att följa GDPR. Vi sparar inga personuppgifter i
      onödan och har färdiga personuppgiftsbiträdesavtal som gör det enkelt för
      er att börja använda Bokaplanen.`,
    ],
  },
  {
    id: 4,
    title: 'Kan jag skriva ut scheman?',
    paragraphs: [
      `Absolut! Vår vision är att det ska vara så enkelt att hålla reda på
      scheman att utskrifter inte behövs. Samtidigt kan det ibland vara skönt
      att ha något att hålla i handen eller sätta upp på väggen på kansliet.
      Därför går alla scheman går att få ut som utskriftsvänliga PDFer - bara
      att ladda ner och skriva ut som vanligt. Men tänk på regnskogen!`,
    ],
  },
  {
    id: 5,
    title: 'Övriga frågor',
    paragraphs: [
      `Undrar du något som inte står med på den här sidan? Eller vill du bara
      prata lite? Tveka inte att höra av dig till oss på
      [info@bokaplanen.se](mailto:info@bokaplanen.se).`,
    ],
  },
];

const PageFAQ = () => (
  <Grid py="baseUp8" px="baseUnit" halign="center">
    <Cell size={{ mobile: 1, smallTablet: 2 / 3 }}>
      <H3 mb="baseUnit">Vanliga frågor</H3>

      <Accordion items={items} />
    </Cell>
  </Grid>
);

PageFAQ.propTypes = {};

export default PageFAQ;
