import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PageHero from '../components/PageHero';
import PageItems from '../components/PageItems';
import Block from '../components/Block';
import PageQuote from '../components/PageQuote';
import PageFAQ from '../components/PageFAQ';
import PageCTA from '../components/PageCTA';
import Grid from '../components/Grid';
import Cell from '../components/Cell';
import H3 from '../components/H3';
import P from '../components/P';

const IndexPage = () => (
  <Layout>
    <SEO title="Start" />

    <PageHero />

    <Block py="baseUp8">
      <PageCTA />
    </Block>

    <Block py="baseUp8" backgroundColor="grayLight">
      <PageQuote team="bp" />
    </Block>

    <Block py="baseUp8">
      <PageItems />
    </Block>

    <Block position="relative" py="baseUp8" backgroundColor="greenLight">
      <Grid py="baseUp8" valign="center" halign="center">
        <Cell size={{ mobile: 1, tablet: 1 / 2 }} px="baseUnit">
          <H3 mb="baseUnit">Integrerar med existerande medlemsregister</H3>
          <P>
            Du behöver inte byta medlemsregister för att använda Bokaplanen - vi
            integrerar med ditt existerande register så att du slipper hålla
            reda på ännu ett medlemssystem!
          </P>
        </Cell>
      </Grid>
    </Block>

    <Block py="baseUp8">
      <PageCTA />
    </Block>

    <Block py="baseUp8" backgroundColor="greenLight">
      <PageFAQ />
    </Block>
  </Layout>
);

export default IndexPage;
