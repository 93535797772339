import styled from 'styled-components';

const Anchor = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.blue};
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 0 0 ${({ theme }) => theme.colors.blue};
  }
`;

export default Anchor;
