import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AccordionItem from './AccordionItem';

const Accordion = ({ items }) => {
  const [activeItem, setActiveItem] = useState('');

  const toggleAccordion = item => {
    setActiveItem(activeItem === item ? '' : item);
  };

  return (
    <>
      {items.map((item, i) => (
        <AccordionItem
          key={`accordion-item-${i}`}
          item={item}
          isOpen={activeItem === i}
          onClick={() => toggleAccordion(i)}
        />
      ))}
    </>
  );
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      paragraphs: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
};

export default Accordion;
