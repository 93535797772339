import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import posed from 'react-pose';

import Block from './Block';
import H4 from './H4';
import PlusMinus from './PlusMinus';
import Markdown from './Markdown';

const Expandable = posed.div({
  closed: { height: 0, opacity: 0 },
  open: { height: 'auto', opacity: 1 },
});

const Content = styled(Expandable)`
  overflow: hidden;
`;

const AccordionButton = styled.button`
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;

  border: 0;
  padding: 0;
  background-color: transparent;

  cursor: pointer;
  outline: none;
`;

const AccordionTitle = styled(H4)`
  flex: 1;
`;

const AccordionItem = ({ item, isOpen, onClick }) => (
  <Block py="baseUnit">
    <AccordionButton onClick={onClick}>
      <PlusMinus isActive={isOpen} />
      <AccordionTitle ml="baseDown2">{item.title}</AccordionTitle>
    </AccordionButton>

    <Content pose={isOpen ? 'open' : 'closed'}>
      <Block
        py={{ mobile: 'baseDown2', smallTablet: 'baseUnit' }}
        px={{ smallTablet: 'baseUp2' }}
      >
        {item.paragraphs.map((paragraph, i) => (
          <Markdown
            key={`accordion-item-${item.id}-p-${i}`}
            content={paragraph}
          />
        ))}
      </Block>
    </Content>
  </Block>
);

AccordionItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    paragraphs: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AccordionItem;
