import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Grid from './Grid';
import Cell from './Cell';
import H3 from './H3';
import P from './P';

const items = [
  {
    title: 'Ett bättre sätt att boka träningstider',
    paragraphs: [
      'Bokaplanen har en kraftfull kalendervy som snabbt och enkelt ger dig en översikt över alla planer och träningstider. Du ser enkelt vilka tider som är bokade för en viss plan eller ett visst lag.',
      'Behöver en träningstid bokas om eller ställas in? Inga problem! Med några få knapptryckningar är tiden ändrad i schemat.',
      'Träningstider växer inte på träd. Med hjälp av Bokaplanen kan du ta till vara på din förenings träningstider så de används så effektivt som möjligt. Resultatet blir fler speltillfällen och gladare medlemmar.',
    ],
  },
  {
    title: 'Lättare kommunikation med ledare och tränare',
    paragraphs: [
      'När alla säsongens träningstider är inbokade mailar systemet ut ett komplett schema till alla lagens ledare och tränare. I mailet kan ni även lägga till övrig information, exempelvis förhållningsregler och koder för inomhushallar.',
      'Så fort en träningstid ändras eller ställs in skickas även ett automatiskt mail ut till rätt ledare och tränare. Ni behöver aldrig mer oroa er för att ni glömt att informera om en schemaändring!',
    ],
  },
  {
    title: 'Håll enkelt reda på alla kostnader',
    paragraphs: [
      'Med inställda och ändrade tider kan det vara svårt att hålla reda på kostnaderna för varje lag. Därför sköter Bokaplanen det åt er, så att bokföringen blir rätt, varje gång.',
      'Kostnaderna för varje lag är tydligt redovisade, och vi har även stöd för att skapa fakturor.',
    ],
  },
];

const PageItems = () => {
  const images = useStaticQuery(graphql`
    query {
      item1: file(relativePath: { eq: "item-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      item2: file(relativePath: { eq: "item-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      item3: file(relativePath: { eq: "item-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      {items.map(({ title, paragraphs }, i) => (
        <Grid
          key={`page-item-${i}`}
          py="baseUp8"
          flexDirection={i % 2 ? '' : 'row-reverse'}
          valign="center"
        >
          <Cell size={{ mobile: 1, tablet: 1 / 2 }} px="baseUnit">
            <H3 mb="baseUnit">{title}</H3>
            {paragraphs.map((paragraph, j) => (
              <P key={`page-item-${i}-p-${j}`}>{paragraph}</P>
            ))}
          </Cell>
          <Cell size={{ mobile: 1, tablet: 1 / 2 }} px="baseUnit">
            <Img fluid={images[`item${i + 1}`].childImageSharp.fluid} />
          </Cell>
        </Grid>
      ))}
    </>
  );
};

export default PageItems;
